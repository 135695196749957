<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"></i18n>
<i18n locale="th" lang="yaml">
field.reason: "สาเหตุการปรับสถานะ"

</i18n>
<template>
	<div class="title-reason">
		<div class="title-icon">
			<a-icon type="info-circle" style="font-size: 30px; color: orange" />
		</div>
		<div class="title-content">
			{{ $t('field.reason') }} : <span>{{ changeRequestData.request_status_reason }}</span>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		changeRequestData: {
			type: null,
			default: () => [],
		},
	},
}
</script>
<style lang="less" scoped>
.title-reason {
	display: flex;
	> .title-icon {
		margin-right: 8px;
	}
	> .title-content {
		align-self: center;
		> span {
			font-weight: bold;
		}
	}
}
</style>